import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import InfoTable from 'components/Web_User_Interface/720p_Series/System/Info/infoTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Info",
  "path": "/Web_User_Interface/720p_Series/System/Info/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address).",
  "image": "./WebUI_720p_SearchThumb_System_Info.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_System_Info.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // Info' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address).' image='/images/Search/WebUI_720p_SearchThumb_System_Info.png' twitter='/images/Search/WebUI_720p_SearchThumb_System_Info.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/System/Info/' locationFR='/fr/Web_User_Interface/720p_Series/System/Info/' crumbLabel="Info" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <h3 {...{
      "id": "system-info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#system-info",
        "aria-label": "system info permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Info`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address).`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aec2d7dd1b105526e25e17c4b4709429/6bbf7/WebUI-System_Info.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAACtklEQVQ4y42U207iUBSGebCZeYw5xHfwKcYxzp1xEjQYKwhVUaJNbECwWuMB6MGWdreAPe69S8FOtNWExHg1kU6U1mSc72Jf7T/rX/9ae2f296nPX75++Pjp28zM5eWlJEk8zwMA9CSappmmSVHU7OwsSZK5XC63upqhaXo5u7yw8JMgCMuy4qtB8DuKojBJFEVBEGSzWZZlT09ZlmUzm5ubJZLc29tnGAZC2Ov1dF3HGKM3QAhHoxFFUaIoShMyxWJxaelXsVSq1+uu4wBVFUVR13T3DY7j+L6/u7vLcZwwIVMsFVdzOYqiGIZxHEcDWkfu9Pt913VhEtd1fd+vVCo8z/8V7+zsLC4uEmtr1WoVY6yqqizLtm2/dQ4hHA6HCfHx8fH8j/m573ONRiMIAgCAIAjdbjculbI9GAwS4qE/GPr+09PTeDzGGMdDMgwjvj2NbdtpMXKhcX09Ho/DMPQmYqACx3EQQjgJQiht2x8MhsPR4+NjFEWe5wEAOh2l1+2ZluV53juB3dyMEIRR+LwSnufJkqwoqmmavV5X1zXbtlOjSqddKGzk84VqtYYQ0jRdluW4Z9u2p80jhNJigiBWVlYODuhareY6TkfuCLzQ718bhmkahj2FNWkkId7YKKyvE6VS6YCmIYS6rquKCgDQgNbv9b0pMMbpwE5Zlp5wdnb2bBtosiybpvkS0r96bjabR0dHDMNwHIcxVhRFEATLsuKVeifti4uLer3eaDRarRbGWNM0WZJd1/2v9eQ4jmGYk5OT18q8oKqqZVnw3fVsNpuHh4cMw7TbbYSQoiiSJHXkjqoCy7ZTaWOME2JJklqtJs/zoigGQXB/f393dxeG4e3tbfx7vBCG4cPDQ6VSeX3P5+fnzIR2u03T9NbWVrlc3t7ejs9pyuUySZL5fP7q6iou/gfwgP85MAFpPwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aec2d7dd1b105526e25e17c4b4709429/e4706/WebUI-System_Info.avif 230w", "/en/static/aec2d7dd1b105526e25e17c4b4709429/d1af7/WebUI-System_Info.avif 460w", "/en/static/aec2d7dd1b105526e25e17c4b4709429/04ac8/WebUI-System_Info.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aec2d7dd1b105526e25e17c4b4709429/a0b58/WebUI-System_Info.webp 230w", "/en/static/aec2d7dd1b105526e25e17c4b4709429/bc10c/WebUI-System_Info.webp 460w", "/en/static/aec2d7dd1b105526e25e17c4b4709429/d8378/WebUI-System_Info.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aec2d7dd1b105526e25e17c4b4709429/81c8e/WebUI-System_Info.png 230w", "/en/static/aec2d7dd1b105526e25e17c4b4709429/08a84/WebUI-System_Info.png 460w", "/en/static/aec2d7dd1b105526e25e17c4b4709429/6bbf7/WebUI-System_Info.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aec2d7dd1b105526e25e17c4b4709429/6bbf7/WebUI-System_Info.png",
              "alt": "Web User Interface - 720p Series - System Info",
              "title": "Web User Interface - 720p Series - System Info",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoTable mdxType="InfoTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      